import { $body } from "../utils/globals";

$(".js-nav-trigger").on("click", function (ev) {
	ev.preventDefault();

	$(this).toggleClass("is-active");

	$body.toggleClass("has-active-nav");
	
});

$('header .menu-item a').on("click",function() {
		
		 if ( $body.hasClass( "has-active-nav" ) ) {
			$body.toggleClass("has-active-nav");
			$(".js-nav-trigger").toggleClass("is-active");	
		 }
		/*$(".js-nav-trigger").toggleClass("is-active");
		$body.toggleClass("has-active-nav");*/
	});