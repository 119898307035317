import { $body } from "../utils/globals";

$(function() {
    


   $(window).on("load scroll resize",function(e){
	var header = $(".header");
    var topbarheight = $(".topbar").height();
    var headerheight = $(".header").height(); 
		//$('.container-header').css('top',topbarheight);

        var scroll = $(window).scrollTop(); 

        if (scroll >= headerheight + topbarheight) {
            header.removeClass('clearHeader').addClass("lightHeader");
        } else {
            header.removeClass("lightHeader").addClass('clearHeader');
        }

    });

});